<template>
  <div class="container" ref="homeContainer">
    <section class="hero" id="hero-section">
      <div class="hero__headline">
        <h1>{{ selectedJob.title }}</h1>
      </div>
      <div class="hero__headline_2">
        <h1>{{ selectedJob.hours }}</h1>
      </div>
    </section>
    <section class="jobSection">
      <div class="jobSection__heading">
        <h1>{{ $t("aboutThePosition") }}</h1>
      </div>
      <div class="jobSection__aboutDesc">
        <h1 v-html="selectedJob.about"></h1>
      </div>
      <div class="jobSection__heading">
        <h1>{{ $t("perfectCandidateLbl") }}</h1>
      </div>
      <div class="jobSection__aboutDesc">
        <h1 v-html="selectedJob.subDesc"></h1>
      </div>
      <div class="jobSection__list">
        <ul>
          <li v-for="(req, index) in selectedJob.requirements" :key="index">
            <span>
              {{ req }}
            </span>
          </li>
        </ul>
      </div>
      <div class="jobSection__heading">
        <h1>{{ $t("whatWeOfferYouLbl") }}</h1>
      </div>
      <div class="jobSection__list">
        <ul>
          <li v-for="(benef, index) in selectedJob.benefits" :key="index">
            <span>
              {{ benef }}
            </span>
          </li>
        </ul>
      </div>
    </section>
    <section class="offersSection">
      <div class="offersSection__see-all">
        <button class="button_skills" @click="goToJobApllicationForm()">
          {{ $t("applyLbl") }}
        </button>
      </div>
    </section>
    <section class="footer" id="footer-section">
      <div class="footer__headline">
        <h1>spinnov</h1>
      </div>
      <div class="footer__social-media">
        <div
          class="footer__social-media__item"
          v-for="option in socialMediaOptions"
          :key="option.id"
        >
          <div
            @click="goToPage(option.link)"
            class="div-background-img"
            :style="{
              backgroundImage:
                'url(' + require(`../assets/${option.image}`) + ')',
            }"
          ></div>
        </div>
      </div>
      <div class="footer__headline_2">
        <h1 v-if="isWindowWidthDesktop">De Run 4547 | 5503 LT Veldhoven</h1>
        <h1 v-else>
          De Run 4547 <br />
          5503 LT Veldhoven
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import { jobs } from "../variables/jobs.js";
export default {
  data() {
    return {
      inWidth: 0,
      availableJobs: jobs,
      selectedJob: jobs[0],
      socialMediaOptions: [
        {
          id: 0,
          link: "https://www.linkedin.com/company/spinnov/",
          image: "linkedinTeam.svg",
        },
      ],
    };
  },
  computed: {
    isWindowWidthDesktop() {
      if (this.inWidth <= 600) {
        return false;
      }
      return true;
    },
  },
  methods: {
    goToPage(link) {
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    goToJobApllicationForm() {
      this.$router.replace(
        `/jobsApplication/${this.$route.path.substring(1).split("/")[1]}`
      );
    },
    setSelectedJob() {
      for (let index = 0; index < this.availableJobs.length; index++) {
        if (
          String(this.availableJobs[index].id) ===
          this.$route.path.substring(1).split("/")[1]
        ) {
          this.selectedJob = this.availableJobs[index];
          return;
        }
      }
      this.$router.replace("/");
    },
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
    this.setSelectedJob();
  },
  created() {
    let ckeditor = document.createElement("script");
    ckeditor.setAttribute("src", "https://use.fontawesome.com/0c4c043ffa.js");
    document.head.appendChild(ckeditor);
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";
@import "../scss/_mixins.scss";

.container {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }
  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }
  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }
}
.hero {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem 0 0rem;
  background-color: #4e3ee0;

  @include respond(laptop) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(small-laptop) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(tablet-land) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    padding: 5rem 0rem 0 0rem;
  }
  &__headline_2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 50%;
    margin-top: 5rem;
    padding-bottom: 8rem;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 2.4rem;
      font-weight: 300;
      opacity: 0.8;
      text-align: left;
      color: #ffffff;
      text-align: center;
      @include respond(desktop) {
        font-size: 2.4rem;
      }

      @include respond(laptop) {
        font-size: 2.2rem;
      }

      @include respond(small-laptop) {
        font-size: 2.1rem;
      }

      @include respond(tablet-land) {
        font-size: 2rem;
      }

      @include respond(phone-land) {
        font-size: 2rem;
      }
    }
  }

  &__headline {
    & > h1 {
      font-family: "Raleway";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 7rem;
      }

      @include respond(small-laptop) {
        font-size: 6rem;
      }

      @include respond(tablet-land) {
        font-size: 5rem;
      }

      @include respond(phone-land) {
        font-size: 4rem;
      }
    }
  }

  &__headline_applyNow {
    margin: 0 auto;
    width: 50%;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 3.2rem;
      font-weight: bold;
      text-align: left;
      color: $color-gray-4;

      @include respond(desktop) {
        font-size: 3.2rem;
      }

      @include respond(laptop) {
        font-size: 3rem;
      }

      @include respond(small-laptop) {
        font-size: 2.8rem;
      }

      @include respond(tablet-land) {
        font-size: 2.6rem;
      }

      @include respond(phone-land) {
        font-size: 2.6rem;
      }
    }
  }

  &__text {
    margin-bottom: -5rem;
    margin-left: -10rem;

    @include respond(laptop) {
      margin-left: -9rem;
    }

    @include respond(small-laptop) {
      margin-left: -12rem;
    }

    @include respond(tablet-land) {
      margin-left: -14rem;
    }

    @include respond(tablet-port) {
      margin-left: 0;
      margin-top: 3rem;
    }

    & > p {
      opacity: 0.6;
      font-family: "Open Sans";
      font-size: 2.4rem;
      line-height: 1.42;

      @include respond(desktop) {
        font-size: 2.3rem;
      }

      @include respond(laptop) {
        font-size: 2.1rem;
      }

      @include respond(small-laptop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone-land) {
        font-size: 1.6rem;
      }
    }
  }

  &__btn-container {
    margin-left: auto;
    margin-right: -15.5rem;
    transform: rotate(-90deg);

    @include respond(laptop) {
      margin-right: -12.5;
    }

    @include respond(phone-land) {
      margin-right: -10rem;
      margin-top: 15rem;
    }
  }

  &__animation {
    margin-top: 5rem;

    @include respond(desktop) {
      margin-top: 4rem;
    }

    @include respond(laptop) {
      margin-top: 3rem;
    }

    @include respond(small-laptop) {
      margin-top: 2rem;
    }

    @include respond(tablet-land) {
      margin-top: 1rem;
    }

    @include respond(phone-land) {
      display: none;
    }
  }
}
.jobSection {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem 0 0rem;

  @include respond(laptop) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(small-laptop) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(tablet-land) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    padding: 5rem 0rem 0 0rem;
  }
  &__heading {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    width: 45%;
    margin-top: 5rem;
    padding-bottom: 0rem;

    @include respond(desktop) {
      width: 45%;
      margin-bottom: 4rem;
    }

    @include respond(laptop) {
      width: 50%;
      margin-bottom: 3.5rem;
    }

    @include respond(small-laptop) {
      width: 50%;
      margin-bottom: 3rem;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
      margin-bottom: 2.5rem;
    }
    & > h1 {
      font-family: "Raleway";
      font-size: 4.8rem;
      font-weight: bold;
      text-align: left;
      @include respond(desktop) {
        font-size: 4.8rem;
      }

      @include respond(laptop) {
        font-size: 4.6rem;
      }

      @include respond(small-laptop) {
        font-size: 4.4rem;
      }

      @include respond(tablet-land) {
        font-size: 4.2rem;
      }

      @include respond(phone-land) {
        font-size: 4rem;
      }
    }
  }
  &__aboutDesc {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 45%;
    margin-top: 0rem;
    padding-bottom: 0rem;

    @include respond(desktop) {
      width: 45%;
    }

    @include respond(laptop) {
      width: 50%;
    }

    @include respond(small-laptop) {
      width: 50%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 2.4rem;
      text-align: left;
      opacity: 0.6;
      @include respond(desktop) {
        font-size: 2.4rem;
      }

      @include respond(laptop) {
        font-size: 2.3rem;
      }

      @include respond(small-laptop) {
        font-size: 2.2rem;
      }

      @include respond(tablet-land) {
        font-size: 2rem;
      }

      @include respond(phone-land) {
        font-size: 1.8rem;
      }
    }
  }
  &__list {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 45%;
    margin-top: 0rem;
    padding-bottom: 0rem;

    @include respond(desktop) {
      width: 45%;
    }

    @include respond(laptop) {
      width: 50%;
    }

    @include respond(small-laptop) {
      width: 50%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > ul {
      list-style-position: inside;
    }
    & > ul > li {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 2.4rem;
      text-align: left;
      @include respond(desktop) {
        font-size: 2.4rem;
      }

      @include respond(laptop) {
        font-size: 2.3rem;
      }

      @include respond(small-laptop) {
        font-size: 2.2rem;
      }

      @include respond(tablet-land) {
        font-size: 2rem;
      }

      @include respond(phone-land) {
        font-size: 1.8rem;
      }
      & > span {
        opacity: 0.6;
      }
    }
  }
}
.footer-section {
  scroll-snap-align: center;
}

.team {
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
  @include respond(desktop) {
    margin-top: 30rem;
  }

  @include respond(laptop) {
    margin-top: 25rem;
  }

  @include respond(small-laptop) {
    margin-top: 20rem;
  }

  @include respond(tablet-land) {
    margin-top: 20rem;
  }

  @include respond(phone-land) {
    margin-top: 15rem;
  }
  &__headline {
    & > h1 {
      font-family: "Raleway";
      font-size: 4.8rem;
      font-weight: 600;
      text-align: center;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 4.8rem;
      }

      @include respond(laptop) {
        font-size: 4.5rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
}

.containerCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}
.containerCard .card {
  position: relative;
  width: 300px;
  height: 400px;
  margin: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerCard .card .content {
  position: absolute;
  bottom: -400px;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: column;
  backdrop-filter: blur(5px);
  background-color: rgba(78, 62, 224, 0.7);
  transition: bottom 0.5s;
  transition-delay: 0s;
}
.containerCard .card:hover .content {
  bottom: 0;
  transition-delay: 0s;
}
.containerCard .card .content .contentBx h3 {
  text-transform: uppercase;
  color: #fff;
  font-family: "Raleway";
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 3.2rem;
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1em;
  transition: 0.5s;
  transition-delay: 0.6s;
  opacity: 0;
  transform: translateY(-20px);
}
.containerCard .card:hover .content .contentBx h3 {
  opacity: 1;
  transform: translateY(0);
}
.containerCard .card .content .contentBx h3 span {
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: initial;
}
.containerCard .card .content .sci {
  position: relative;
  bottom: 10px;
  display: flex;
}
.containerCard .card .content .sci li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i));
}

.containerCard .card:hover .content .sci li {
  transform: translateY(0);
  opacity: 1;
}
.containerCard .card .content .sci li a {
  color: white;
  font-size: 24px;
}
.offersSection {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 15rem auto 0 auto;
  width: 50%;
  @include respond(laptop) {
    margin: 15rem auto 0 auto;
    width: 55%;
  }

  @include respond(small-laptop) {
    margin: 12rem auto 0 auto;
    width: 60%;
  }

  @include respond(tablet-land) {
    margin: 12rem auto 0 auto;
    width: 70%;
  }
  @include respond(phone-land) {
    margin: 3rem auto 0 auto;
    width: 90%;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__see-all {
    display: flex;
    justify-content: center;
    margin: 8rem auto 20rem auto;

    @include respond(tablet-port) {
      margin: 8rem auto 10rem auto;
      width: 100%;
    }
    @include respond(phone-land) {
      margin: 8rem auto 8rem auto;
      width: 100%;
    }
    @include respond(phone) {
      margin: 8rem auto 7rem auto;
      width: 100%;
    }
  }
}

.teamImgSection {
  &__img-wrapper {
    width: 80%;
    justify-content: center;
    margin: 0rem auto 15rem auto;
    @include respond(phone-land) {
      width: 90%;
    }
    @include respond(phone) {
      width: 95%;
    }
  }
  &__img {
    width: 100%;
  }
}

.footer {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem 10rem 0rem;

  @include respond(laptop) {
    padding: 8rem 0rem 15rem 0rem;
  }

  @include respond(small-laptop) {
    padding: 5rem 0rem 15rem 0rem;
  }

  @include respond(tablet-land) {
    padding: 5rem 0rem 15rem 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    padding: 5rem 0rem 15rem 0rem;
  }
  &__headline_2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 50%;
    margin-top: 5rem;
    padding-bottom: 8rem;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 4.5rem;
      font-weight: 300;
      text-align: left;
      color: #110b47;
      opacity: 0.6;
      text-align: center;
      @include respond(desktop) {
        font-size: 4.5rem;
      }

      @include respond(laptop) {
        font-size: 4.2rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 3.8rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }

  &__headline {
    & > h1 {
      font-family: "Montserrat";
      font-size: 13.7rem;
      font-weight: 600;
      text-align: center;
      color: #110b47;
      @include respond(desktop) {
        font-size: 13.7rem;
      }

      @include respond(laptop) {
        font-size: 12rem;
      }

      @include respond(small-laptop) {
        font-size: 11rem;
      }

      @include respond(tablet-land) {
        font-size: 10rem;
      }

      @include respond(phone-land) {
        font-size: 9rem;
      }
    }
  }
  &__social-media {
    display: flex;
    justify-content: center; //should be space-between when more than 1
    align-items: center;
    max-width: 50rem;
    margin: 5rem auto 5rem auto;

    @include respond(desktop) {
      margin: 5rem auto 5rem auto;
      max-width: 50rem;
    }

    @include respond(laptop) {
      margin: 5rem auto 5rem auto;
      max-width: 45rem;
    }

    @include respond(small-laptop) {
      margin: 4rem auto 4rem auto;
      max-width: 45rem;
    }

    @include respond(tablet-land) {
      margin: 4rem auto 5rem auto;
      max-width: 35rem;
    }

    @include respond(phone-land) {
      margin: 3rem auto 3rem auto;
      max-width: 30rem;
    }

    &__item {
      cursor: pointer;

      & > div {
        width: 7rem;
        height: 7rem;

        @include respond(desktop) {
          width: 7rem;
          height: 7rem;
        }

        @include respond(laptop) {
          width: 6.2rem;
          height: 6.2rem;
        }

        @include respond(small-laptop) {
          width: 6rem;
          height: 6rem;
        }

        @include respond(tablet-land) {
          width: 5.5rem;
          height: 5.5rem;
        }

        @include respond(phone-land) {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }
}
.button_skills {
  border: none;
  background: #e73151;
  padding: 20px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}
</style>
